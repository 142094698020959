<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">In Odyssey, build each of the following compounds:</p>

      <ul class="mb-2">
        <li>Carvone</li>
        <li>Dihydrocaveol</li>
        <li>Limonene</li>
      </ul>

      <p class="mb-2">
        <a href="/img/assignments/UCIrvine/odysseyInstructions.png" download>
          Building the structures:
        </a>
        (click on this link to download an image of the steps if the resolution of the image below
        is inadequate).
      </p>

      <p class="mb-5 pl-4">
        <v-img style="max-width: 100%" src="/img/assignments/UCIrvine/odysseyInstructions.png" />
      </p>

      <p class="mb-4">
        Record the dipoles for each of the 3 molecules (to 1 decimal place) in the table below:
      </p>

      <table>
        <thead>
          <tr>
            <th style="text-align: left">Compound</th>
            <th>Dipole (Debye)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Carvone
              <chemical-latex content="C10H14O" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input1"
                class="centered-input ml-5 my-2"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              Dihydrocarveol
              <chemical-latex content="C10H18O" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input2"
                class="centered-input ml-5 my-2"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              Limonene
              <chemical-latex content="C10H16" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input3"
                class="centered-input ml-5 my-2"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LDQ2S2Q5',
  components: {
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
};
</script>
<style scoped></style>
